import { CardsContainer } from "shared/components/CardsContainer";
import { MaxWidth } from "shared/components/MaxWidth";
import { OverviewIntro } from "shared/components/OverviewIntro";
import { Page } from "ineo/components/Page";
import { Spacing } from "shared/components/Spacing";
import { TINY, SMALL, LARGE } from "shared/utils/constants";

export const HubOverviewPage = ({
  footer,
  intro,
  items,
  mirrorPath,
  navigation,
  seo,
  title,
}) => (
  <Page
    description={seo?.description || intro}
    footer={footer}
    image={seo?.image}
    mirrorPath={mirrorPath}
    navigation={navigation}
    title={seo?.title || title}
  >
    <MaxWidth size={LARGE}>
      <Spacing size={SMALL}>
        <OverviewIntro description={intro} title={title} />
      </Spacing>
      <Spacing size={TINY}>
        <CardsContainer items={items || []} />
      </Spacing>
    </MaxWidth>
  </Page>
);
