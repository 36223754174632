import { usePreview } from "clariah/utils/sanity";
import { DetailPage } from "./DetailPage";

import { undefinedToNull } from "shared/utils/undefinedToNull";

export const DetailPagePreview = ({ query, params }) => {
  const data = usePreview(null, query, params);

  delete data.navigation;
  delete data.footer;

  return <DetailPage {...undefinedToNull(data)} />;
};
