import { Page } from "ineo/components/Page";
import { LoaderContainer } from "shared/components/Loader";

export const LoadingPage = ({
  footer,
  intro,
  mirrorPath,
  navigation,
  related,
  seo,
  title,
}) => (
  <Page
    description={seo?.description || intro}
    footer={footer}
    footerHasBorder
    image={seo?.image}
    mirrorPath={mirrorPath}
    navigation={navigation}
    title={seo?.title || title}
  >
    <LoaderContainer />
  </Page>
);
