import { BLOG_TYPE, EVENT_TYPE, NEWS_TYPE } from "utils/constants";
import { LARGE, PROJECT_TYPE, SMALL } from "shared/utils/constants";
import { CardsContainer } from "shared/components/CardsContainer";
import { Content } from "shared/components/Content";
import { Hero } from "shared/components/Hero";
import { MaxWidth } from "shared/components/MaxWidth";
import { Page } from "ineo/components/Page";
import { RELATED, translate } from "shared/utils/translate";
import { Spacing } from "shared/components/Spacing";

export const DetailPage = ({
  _type,
  author,
  body,
  footer,
  heroImage,
  intro,
  locale,
  location,
  mirrorPath,
  navigation,
  publishedAt,
  related,
  seo,
  title,
}) => (
  <Page
    description={seo?.description || intro}
    footer={footer}
    image={seo?.image}
    mirrorPath={mirrorPath}
    navigation={navigation}
    title={seo?.title || title}
    hasRelated={related && related.length > 0}
  >
    <Hero
      type={_type}
      location={location}
      date={publishedAt}
      title={title}
      lead={intro}
      image={heroImage}
      author={author}
    />

    <Spacing size={SMALL}>
      <Content
        data={body}
        dropCapDisabled={
          [BLOG_TYPE, EVENT_TYPE, NEWS_TYPE, PROJECT_TYPE].indexOf(_type) === -1
        }
      />
    </Spacing>

    {related && related.length > 0 && (
      <Spacing>
        <MaxWidth size={LARGE}>
          <CardsContainer
            allLinkType={_type}
            items={related}
            title={translate(RELATED, locale)}
          />
        </MaxWidth>
      </Spacing>
    )}
  </Page>
);
