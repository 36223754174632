import { DetailPage } from "containers/DetailPage";
import { DetailPagePreview } from "containers/DetailPagePreview";
import { getStaticPathsForTypes } from "utils/staticHelpers";
import { HUB_OVERVIEW_TYPE, LANDING_TYPE, PAGE_TYPE } from "utils/constants";
import { HubOverviewPage } from "containers/HubOverviewPage";
import { LandingPage } from "containers/LandingPage";
import { LoadingPage } from "containers/LoadingPage";
import { useRouter } from "next/router";
import { fetchQuery } from "ineo/utils/sanity";
import { undefinedToNull } from "shared/utils/undefinedToNull";
import { slugQuery as query } from "utils/staticHelpers";
import { PreviewSuspense } from "next-sanity/preview";

export async function getStaticProps({ preview = false, params: { slug } }) {
  const types = [HUB_OVERVIEW_TYPE, LANDING_TYPE, PAGE_TYPE];

  const params = { slug };

  if (preview) {
    return { props: { preview, data: { params } } };
  }

  const data = await fetchQuery({
    preview,
    params: {
      slug,
      types,
    },
    query: query(true),
  });

  if (!types.includes(data?._type)) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      preview,
      data: undefinedToNull(data),
    },
  };
}

export async function getStaticPaths() {
  return await getStaticPathsForTypes({
    types: [HUB_OVERVIEW_TYPE, LANDING_TYPE, PAGE_TYPE],
  });
}

const PageToExport = ({ preview, data }) => {
  const { isFallback } = useRouter();

  if (isFallback) {
    return <LoadingPage />;
  }

  if (data._type === LANDING_TYPE) {
    return <LandingPage {...data} />;
  }

  if (data._type === HUB_OVERVIEW_TYPE) {
    return <HubOverviewPage {...data} />;
  }

  return preview ? (
    <PreviewSuspense>
      <DetailPagePreview {...data} />
    </PreviewSuspense>
  ) : (
    <DetailPage {...data} />
  );
};

export default PageToExport;
