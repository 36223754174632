import { CardsSectionContainer } from "shared/components/CardsSection";
import { CtaSection } from "shared/components/CtaSection";
import { IconGridSection } from "shared/components/IconGridSection";
import { LandingHero } from "shared/components/LandingHero";
import { LogoSectionContainer } from "shared/components/LogoSection";
import { OffsetImageWithContentSection } from "shared/components/OffsetImageWithContentSection";
import { Page } from "ineo/components/Page";
import { QuoteSectionContainer } from "shared/components/QuoteSection";
import { RichTextSectionContainer } from "shared/components/RichTextSection";
import { RESOURCE_FINDER_TYPE } from "ineo/utils/constants";

export const LandingPage = ({
  body,
  cards,
  resourceType,
  cta,
  footer,
  heroImage,
  heroList,
  heroListTitle,
  iconGrid,
  intro,
  logos,
  mirrorPath,
  navigation,
  offsetImage,
  quote,
  seo,
  title,
}) => {
  if (cta?.button?.page?._type === RESOURCE_FINDER_TYPE && resourceType?.href) {
    cta.button.page.slug = { current: `?${resourceType.href}` };
  }

  return (
    <Page
      description={seo?.description || intro}
      footer={footer}
      image={seo?.image}
      mirrorPath={mirrorPath}
      navigation={navigation}
      title={seo?.title || title}
    >
      <LandingHero
        title={title}
        intro={intro}
        image={heroImage}
        list={heroList}
        listTitle={heroListTitle}
      />
      {offsetImage && (
        <OffsetImageWithContentSection
          {...offsetImage}
          isOnDark={Boolean(iconGrid)}
        />
      )}
      {iconGrid && <IconGridSection {...iconGrid} />}
      {quote && <QuoteSectionContainer {...quote} />}
      {body && <RichTextSectionContainer body={body} />}
      {cards && <CardsSectionContainer {...cards} />}
      {logos && (
        <LogoSectionContainer
          {...logos}
          hasBorderTop
          hasBorderBottom={!Boolean(cta)}
        />
      )}
      {cta && <CtaSection {...cta} />}
    </Page>
  );
};
